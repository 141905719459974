import { Grid, Typography, Box } from '@mui/material';
import React from 'react';
import img from './assets/aboutusbg3.webp';

function Aboutus() {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
                position: "relative",
                padding: { xs: "16px", md: "24px" },
                margin: 0,
                minHeight: "100vh", // Full viewport height
                overflow: "hidden",
            }}
        >
            {/* Blurred Background */}
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${img})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    filter: "blur(8px)", // Blur effect for the background
                    zIndex: 1,
                }}
            />

            {/* Semi-transparent Overlay for Better Text Visibility */}
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay
                    zIndex: 2,
                }}
            />

            {/* Content Container */}
            <Grid
                item
                xs={12}
                md={8}
                sx={{
                    position: "relative", // To position above the blurred background and overlay
                    zIndex: 3,
                    padding: { xs: "16px", md: "24px" },
                }}
            >
                <Typography
                    variant="h3"
                    textAlign="center"
                    gutterBottom
                    fontWeight="bold"
                    color="#fff"
                    sx={{
                        fontSize: { xs: "1.8rem", md: "2.5rem" },
                    }}
                >
                    Degree Colleges Bangalore
                </Typography>
                <Typography
                    variant="body1"
                    textAlign="justify"
                    color="#fff"
                    paragraph
                    sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        lineHeight: { xs: "1.5", md: "1.8" },
                    }}
                >
                    Located in Bengaluru, a hub of education and innovation, our institution is a leading center for undergraduate programs in Arts, Science, Commerce, and Management. We are committed to academic excellence through innovative teaching, a robust curriculum, and guidance from experienced faculty.
                    Holistic development is at the heart of our mission. Beyond academics, we encourage participation in sports, cultural activities, and leadership programs to nurture creativity, critical thinking, and personal growth.
                </Typography>

                <Box my={2} /> {/* Line gap */}

                <Typography
                    variant="body1"
                    textAlign="justify"
                    color="#fff"
                    paragraph
                    sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        lineHeight: { xs: "1.5", md: "1.8" },
                    }}
                >
                    Our state-of-the-art infrastructure, including modern labs, libraries, and recreational facilities, supports a dynamic and enriching campus life.
                    Strong industry connections ensure students gain practical exposure through internships, workshops, and placements, preparing them for successful careers. With a vibrant learning environment, we empower students to excel in their fields, contribute to society, and adapt to global challenges.
                    Join us in shaping a brighter future, where education meets innovation and character leads the way.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Aboutus;
