import { Grid, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import image from './assets/defaultForm.jpg';

const Form = ({ applyButtonColor = '#4c4cff', submitButtonColor = '#4c4cff' }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    setIsFormOpen(true); // Automatically open the form when the page loads
  }, []);

  const styles = {
    container: {
      minHeight: '100vh',
      display: 'flex',
      backgroundColor: '#e0ffff',
      padding: '0 16px', // Added padding for container
      borderRadius: '15px', // Rounded corners for the whole container
      transition: 'transform 0.3s ease-in-out', // Added transition for smooth effect
    },
    leftGrid: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center', // Center the "Apply Now" button
      padding: { xs: '16px', md: '24px' }, // Adjust padding for mobile and desktop
      textAlign: 'left', // Ensure text is left-aligned
      borderRadius: '15px', // Rounded corners for left grid
      transition: 'transform 0.3s ease-in-out', // Apply transition for smooth scale
      '&:hover': {
        transform: 'scale(1.05)', // Apply hover effect with scaling
      },
    },
    title: {
      fontSize: { xs: '24px', md: '30px' }, // Responsive font size
      fontWeight: 'bold',
      textAlign:'center',
      color: '#3aafa9',
      marginBottom: '10px',
    },
    description: {
      fontSize: { xs: '16px', md: '19px' }, // Responsive font size
      color: '#495057',
      lineHeight: '1.5',
      marginBottom: '20px',
      textAlign: 'justify',
      margin:'15px',
    },
    fullImage: {
      width: '100%', // Ensure the image fills the available width
      height: 'auto', // Maintain aspect ratio for the image
      objectFit: 'cover',
      borderRadius: '15px', // Round corners of the image
    },
    rightGrid: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: { xs: '10px', md: '24px' }, // Adjust padding for mobile and desktop
      
     
     
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#5e9ea0',
      fontSize: '20px',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      minWidth: { xs: '260px', md: '300px' }, // Adjust dialog width for smaller screens
      borderRadius: '15px', // Rounded corners for the dialog
    },
    formField: {
      marginBottom: '20px',
      '& .MuiInputBase-root': {
        backgroundColor: '#b0dfe5',
        borderRadius: '10px', // Rounded corners for input fields
      },
    },
    closeButton: {
      color: '#5e9ea0',
    },
    applyButton: {
      padding: '12px 24px', // Added padding for the button
      backgroundColor: '#3aafa9',
      color: '#ffffff',
      borderRadius: '10px', // Rounded corners for the button
      '&:hover': { backgroundColor: '#327e7b' },
      transition: 'background-color 0.3s ease-in-out', // Added transition for hover effect
    },
  };

  const openForm = () => setIsFormOpen(true);
  const closeForm = () => setIsFormOpen(false);

  return (
    <>
      <Grid container style={styles.container}>
        <Grid item xs={12} md={6} style={styles.leftGrid}>
          <Typography variant="h2" sx={styles.title}>Unlock Your Future with Scholarships</Typography>
          <Typography sx={styles.description }>
            Bangalore’s top degree colleges are hubs of academic excellence and innovation, offering diverse programs tailored to global standards.
            With experienced faculty, cutting-edge facilities, and a focus on holistic development, these institutions nurture talent and prepare students for successful careers.
            Their emphasis on quality education, skill-building, and extracurricular growth makes them the ideal choice for aspiring learners.
          </Typography>
          <Button
            variant="contained"
            onClick={openForm}
            sx={styles.applyButton} // Apply the button styles
          >
            Apply Now
          </Button>
        </Grid>
        <Grid item xs={12} md={6} style={styles.rightGrid}>
          <img src={image} alt="Scholarship" style={styles.fullImage} />
        </Grid>
      </Grid>

      <Dialog open={isFormOpen} onClose={closeForm}>
        <DialogTitle sx={styles.dialogTitle}>
          <span>Apply for College</span>
          <IconButton onClick={closeForm} sx={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            sx={styles.formField}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            sx={styles.formField}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeForm}
            sx={{
              backgroundColor: '#3aafa9',
              color: '#ffffff',
              '&:hover': { backgroundColor: '#327e7b' },
              borderRadius: '10px', // Rounded corners for the button
              transition: 'background-color 0.3s ease-in-out', // Added transition for hover effect
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => alert('Form submitted!')}
            sx={{
              backgroundColor: '#3aafa9',
              color: '#ffffff',
              '&:hover': { backgroundColor: '#327e7b' },
              borderRadius: '10px', // Rounded corners for the button
              transition: 'background-color 0.3s ease-in-out', // Added transition for hover effect
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Form;
