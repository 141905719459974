import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        background: '#3aafa9',
        color: '#fff',
        py: 2,
        textAlign: 'center',
      }}
    >
      <Typography variant="body2" sx={{ color: '#e0e0e0' }}>
        Copyrights @ 2024 - DegreeColleges.com All Rights Reserved.
      </Typography>
    </Box>
  );
}

export default Footer;