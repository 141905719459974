import React from 'react'
import Cardss from './components/Cardss'
import Header from './components/Header'
//import Banner from './components/Banner'
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer';
import Aboutus from './components/Aboutus';
import Banner from './components/Banner';
import Form from './components/Form';
const App = () => {
  return (
    <div>
      <BrowserRouter>
      <Header />
      <Banner />
      <Cardss />
      <Aboutus />
      <Form />
      <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
