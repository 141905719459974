import React from "react";
import image1 from './assets/bms_college.png';
import image2 from "./assets/DayanandaSagarCollege.jpg";
import image3 from "./assets/CambridgeCollege.jpg";
import image4 from "./assets/presedency_college.jpg";
import {
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
    Button,
    Link as MuiLink,
} from "@mui/material";

const colleges = [
    {
        name: "BMS COLLEGE",
        desc: "Empowering aspirations, BMS College stands as a beacon of innovation and academic brilliance.",
        image: image1,
    },
    {
        name: "Dayananda Sagar College",
        desc: "Dayananda Sagar College empowers aspirations with innovation and academic excellence",
        image: image2,
    },
    {
        name: "Cambridge College",
        desc: "Cambridge College builds bright futures, inspiring limitless possibilities",
        image: image3,
    },
    {
        name: "Presidency College",
        desc: "Where leaders are shaped and excellence thrives—Presidency College inspires greatness.",
        image: image4,
    },
];

const Cardss = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#e0ffff",
                py: { xs: 4, sm: 6 }, // Adjust padding for mobile
                px: { xs: 2, sm: 3 },
            }}
        >
            <Typography
                variant="h4"
                textAlign="center"
                fontWeight="bold"
                color="#3aafa9"
                gutterBottom
                sx={{
                    fontSize: { xs: '1.8rem', sm: '2.5rem' }, // Adjust font size for mobile
                }}
            >
                Degree Colleges in Bangalore
            </Typography>
            <Typography
                variant="body1"
                textAlign="center"
                color="text.secondary"
                mb={4}
                sx={{
                    fontSize: { xs: '0.9rem', sm: '1rem' }, // Adjust font size for mobile
                }}
            >
                Explore the best colleges with excellent courses and career opportunities.
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {colleges.map((college, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card
                            sx={{
                                boxShadow: 4,
                                borderRadius: 3,
                                transition: "transform 0.3s, box-shadow 0.3s",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    boxShadow: 8,
                                    backgroundColor: '#dcf0ee',
                                },
                            }}
                        >
                            <CardMedia
                                component="img"
                                height="200"
                                image={college.image}
                                alt={`Image of ${college.name}`}
                                sx={{
                                    filter: "brightness(0.9)",
                                    "&:hover": { filter: "brightness(1)" },
                                }}
                            />
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    color="#333"
                                    gutterBottom
                                    sx={{
                                        fontSize: { xs: '15px', sm: '17px' }, // Adjust font size for mobile
                                    }}
                                >
                                    {college.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: { xs: '0.8rem', sm: '0.9rem' }, // Adjust font size for mobile
                                    }}
                                >
                                    {college.desc}
                                </Typography>
                                <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            
                                            textTransform: "capitalize",
                                            fontWeight: "bold",
                                            fontSize: { xs: '0.8rem', sm: '1rem' }, // Adjust font size for mobile
                                            backgroundColor: '#3aafa9',
                                            "&:hover": {
                                                backgroundColor: '#319d97',
                                            },
                                        }}
                                    >
                                        <MuiLink style={{textDecoration:'none'}} href="https://www.getmycollege.com/colleges?state=karnataka" color="inherit">
                                            Learn more
                                        </MuiLink>
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box
                mt={6}
                display="flex"
                justifyContent="center"
            >
                <Button
                    variant="contained"
                    size="small"
                    sx={{
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        fontSize: { xs: '1rem', sm: '1.25rem' }, // Adjust font size for mobile
                        py: { xs: 1, sm: 1.5 }, // Increase padding for mobile
                        px: { xs: 2, sm: 3 },
                        backgroundColor: '#3aafa9',
                        "&:hover": {
                            backgroundColor: '#319d97',
                        },
                    }}
                >
                    <MuiLink  style={{textDecoration:'none'}} href="https://www.getmycollege.com/colleges?state=karnataka" color="inherit">
                        Explore more
                    </MuiLink>
                </Button>
            </Box>
        </Box>
    );
};

export default Cardss;
