import React, { useState } from 'react';
import { Grid, Button, Drawer, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import image from './assets/logo.jpg';
import MenuIcon from '@mui/icons-material/Menu';

function Header() {
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleDrawer = (open) => {
        setOpenDrawer(open);
    };

    const setClose = () => {
        setOpenDrawer(false);
    };

    // Button styles for both mobile and desktop
    const buttonStyles = {
        color: '#fff',  // Default color for desktop
        fontWeight: 'bold',
        fontSize: { xs: '14px', md: '17px' },
       
       
    };

    // Button styles for mobile (white text on #3aafa9 background)
    const mobileButtonStyles = {
        color: '#fff',  // White text
        backgroundColor: '#3aafa9',  // Background color
        fontWeight: 'bold',
        fontSize: '16px',
        textTransform: 'none', // Avoid uppercasing of text
        '&:hover': {
            backgroundColor: '#2b8c8a',  // Slightly darker shade on hover
        },
    };

    const menuItems = (
        <>
            <Button
                onClick={() => {
                    navigate('/Home'); 
                    setClose();      
                }}
                sx={buttonStyles}
            >
                HOME
            </Button>
            <Button
                onClick={() =>{ 
                    navigate('/https://www.getmycollege.com/about-us');  
                    setClose(); 
                }}
                sx={buttonStyles}
            >
                About us
            </Button>
            <Button
                onClick={() => navigate('/https://www.getmycollege.com/contact-us')}
                sx={buttonStyles}
            >
                Contact
            </Button>
            <Button
                onClick={() => navigate('/https://www.getmycollege.com/colleges?state=karnataka')}
                sx={buttonStyles}
            >
                Degree Colleges
            </Button>
            <Button sx={buttonStyles}>Login</Button>
        </>
    );

    const mobileMenuItems = (
        <>
            <Button
                onClick={() => {
                    navigate('/Home'); 
                    setClose();      
                }}
                sx={mobileButtonStyles}
            >
                HOME
            </Button>
            <Button
                onClick={() =>{ 
                    navigate('/https://www.getmycollege.com/about-us');  
                    setClose(); 
                }}
                sx={mobileButtonStyles}
            >
                About us
            </Button>
            <Button
                onClick={() => navigate('/https://www.getmycollege.com/contact-us')}
                sx={mobileButtonStyles}
            >
                Contact
            </Button>
            <Button
                onClick={() => navigate('/https://www.getmycollege.com/colleges?state=karnataka')}
                sx={mobileButtonStyles}
            >
                Degree Colleges
            </Button>
            <Button sx={mobileButtonStyles}>Login</Button>
        </>
    );

    return (
        <Grid>
            <Grid
                container
                sx={{
                    backgroundColor: '#3aafa9',
                    padding: '10px',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                }}
            >
                {/* Logo Section */}
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={image}
                        alt="logo"
                        style={{
                            height: '100px',
                            width: '100px',
                            marginLeft: '40px',
                            borderRadius: '20%',
                        }}
                    />
                </Grid>

                {/* Mobile Menu Icon */}
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: { xs: 'flex', md: 'none' },
                        justifyContent: 'flex-end',
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="menu"
                        onClick={() => toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Grid>

                {/* Desktop Navigation Buttons */}
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: { md: 'flex', xs: 'none' },
                        justifyContent: 'flex-end',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            gap: { xs: 1, md: 3 },
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        {menuItems}
                    </Grid>
                </Grid>
            </Grid>

            {/* Drawer for Mobile */}
            <Drawer
           
                anchor="right"
                open={openDrawer}
                onClose={() => toggleDrawer(false)}
            >
                <Grid
                    sx={{
                        height:'1000px',
                        bgcolor:'#e0ffff',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '20px',
                        gap: '15px',
                        color: '#000'
                    }}
                >
                    {mobileMenuItems}
                </Grid>
            </Drawer>
        </Grid>
    );
}

export default Header;
