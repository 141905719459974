import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, IconButton, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircle from '@mui/icons-material/CheckCircle';

import OIP from './assets/NagarjunaCollege.jpg';
import OIP1 from './assets/RevaCollege.jpg';
import OIP2 from './assets/presedencyCollege.jpg';
import OIP3 from './assets/CambridgeCollege.jpg';

const Banner = () => {
    const images = [OIP, OIP1, OIP2, OIP3]; // Array of background images
    const [currentImageIndex, setCurrentImageIndex] = useState(0); // State to track the current image

    // Change the background image automatically every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext(); // Automatically move to the next image
        }, 3000);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    // Handler to move to the next image
    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    // Handler to move to the previous image
    const handlePrevious = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <Box
            sx={{
                minHeight: { xs: '40vh', md: '80vh' }, // Fullscreen height
                position: 'relative',
                backgroundImage: `url(${images[currentImageIndex]})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'center',
                transition: 'none',
                borderRadius: '0',
                overflow: 'hidden',
            }}
        >
            {/* Overlay for transparent color */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1,
                }}
            />

            <Grid
                container
                sx={{
                    zIndex: 2,
                    position: 'relative',
                }}
            >
                {/* Left Side: Text */}
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        ml:{md:'30px',xs:'0px'},
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                        padding: { xs: 2, sm: 4 },
                        color: '#FFFFFF',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: '1.3rem', md: '4rem' }, // Increased font size for desktop
                            fontWeight: 'bold',
                            textShadow: '4px 4px 12px rgba(0, 0, 0, 0.7)',
                            letterSpacing: '3px',
                            fontStyle: 'italic',
                            marginBottom: '20px',
                        }}
                    >
                        "Transforming aspirations into success in Bengaluru"
                    </Typography>
                </Grid>

                {/* Right Side: Form */}
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                        display: {md:'flex',xs:'none'},
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            backdropFilter: 'blur(10px)',
                            border: '1px solid rgba(255, 255, 255, 0.3)',
                            padding: { xs: '8px', sm: '15px', md: '30px' }, // Adjust padding for desktop
                            borderRadius: '12px',
                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                            width: { xs: '75%', sm: '75%', md: '75%' }, // Adjust width for desktop
                            textAlign: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                marginBottom: 2,
                                fontWeight: 'bold',
                                fontSize: { xs: '1.2rem', sm: '1.6rem', md: '2rem' }, // Increased font size for desktop
                                color: '#FFFFFF',
                            }}
                        >
                            Apply for Degree Colleges
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                marginBottom: 3,
                                fontWeight: '400',
                                fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' }, // Increased font size for desktop
                                color: '#FFFFFF',
                            }}
                        >
                            Empower your education, apply to colleges today.
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder="Name"
                            variant="outlined"
                            name="name"
                            sx={{
                                marginBottom: '15px',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                borderRadius: '8px',
                                fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' }, // Increased input font size for desktop
                            }}
                        />
                        <TextField
                            fullWidth
                            placeholder="Phone Number"
                            variant="outlined"
                            name="phone"
                            sx={{
                                marginBottom: '15px',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                borderRadius: '8px',
                                fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' }, // Increased input font size for desktop
                            }}
                        />

                        <Button
                            variant="contained"
                            startIcon={<CheckCircle />}
                            fullWidth
                            sx={{
                                backgroundColor: '#005e69',
                                fontWeight: 'bold',
                                padding: '8px 12px', // Reduced padding
                                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' }, // Increased button font size for desktop
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: '#3aafa9',
                                },
                            }}
                        >
                            Apply Now
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {/* Sliding Arrows */}
            <IconButton
                onClick={handlePrevious}
                sx={{
                    position: 'absolute',
                    left:{ md:'20px',xs:'10px'},
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 4,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                }}
            >
                <ArrowBackIosIcon />
            </IconButton>
            <IconButton
                onClick={handleNext}
                sx={{
                    position: 'absolute',
                    right:{ md:'20px',xs:'10px'},
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 4,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                }}
            >
                <ArrowForwardIosIcon />
            </IconButton>
        </Box>
    );
};

export default Banner;
